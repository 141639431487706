import React from "react";

const Header = () => {
  return (
    <div className="container mt-5">
      <div className="unselectable">
        <h2>Will Tholke</h2>
        <p className="lead">
          Software Engineer @ Daffy | San Francisco
        </p>
      </div>
    </div>
  );
};

export default Header;