import React from "react";

const Footer = () => {
  return (
    <div className="container mt-4">
      <footer className="pt-3 mt-3 text-muted border-top">
        <p>
          <span className="unselectable">
            &copy; {" "}
          </span>
          Will Tholke 2025
          <span className="unselectable">
            {" – "}
          </span>
          <a
            href="https://www.linkedin.com/in/willtholke/"
            target="_blank"
            className="text-muted"
            rel="noreferrer"
          >
          LinkedIn
          </a>
          <span className="unselectable">
            {" – "}
          </span>
          <a
            href="https://github.com/willtholke"
            target="_blank"
            className="text-muted"
            rel="noreferrer"
          >
            GitHub
          </a>
        </p>
      </footer>
    </div>
  );
};

export default Footer;
