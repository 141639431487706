import Header from "../../components/shared/Header";
import Navigation from "../shared/Navigation";
import Footer from "../shared/Footer";
import NoteSection from "./components/Sections/NoteSection";
import ExamSection from "./components/Sections/ExamSection";

const NotesPage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <NoteSection />
      <ExamSection />
      <Footer />
    </div>
  );
};

export default NotesPage;
