import React, {useEffect} from "react";
import Header from "../../components/shared/Header";
import Navigation from "../shared/Navigation";
import Footer from "../shared/Footer";
import ExperienceSection from "./components/Sections/ExperienceSection";
import AwardSection from "./components/Sections/AwardSection";
import CommunitySection from "./components/Sections/CommunitySection";
import EducationSection from "./components/Sections/EducationSection";
import TeachingSection from "./components/Sections/TeachingSection";
import SkillsSection from "./components/Sections/SkillsSection";

const ResumePage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <ExperienceSection />
            <AwardSection />
            <CommunitySection />
          </div>
          <div className="col md-6">
            <EducationSection />
            <SkillsSection />
            <TeachingSection />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ResumePage;
