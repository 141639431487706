import React from "react";
import styled from "styled-components";

interface ProjectCardProps {
  title: string;
  description: string;
  githubUrl?: string;
  paperUrl?: string;
  githubDisabled?: boolean;
  paperDisabled?: boolean;
}

const Container = styled.div<{ randomDegree: string }>`
  position: relative;
  width: 100%;
  height: 200px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background: linear-gradient(
    ${(props) => props.randomDegree}deg,
    #0275d8,
    #bfdaff
  );
  background-size: 400% 400%;
  -webkit-animation: AnimationName 10s ease infinite;
  -moz-animation: AnimationName 10s ease infinite;
  animation: AnimationName 10s ease infinite;
`;


const TextContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;

const Title = styled.h5`
  margin: 0;
  font-size: 1.5rem;
  color: white;
`;

const ProjectCard = ({...props}: ProjectCardProps) => {
  const randomDegree = Math.floor(Math.random() * 360);

  return (
    <div className="col-lg-3 mb-4">
      <div className="card">
        <Container randomDegree={randomDegree.toString()}>
          <TextContainer>
            <Title className="unselectable">{props.title}</Title>
          </TextContainer>
        </Container>
        <div className="card-body">
          <h5 className="card-title">{props.title}</h5>
          <p className="card-text">{props.description}</p>
          <div>
            {(props.githubUrl || props.githubDisabled) && (
              <a
                href={props.githubUrl}
                className={`btn btn-primary mx-1 ${
                  props.githubDisabled ? "disabled" : ""
                }`}
                target="_blank"
                rel="noreferrer"
                draggable="false"
              >
                GitHub
              </a>
            )}
            {(props.paperUrl || props.paperDisabled) && (
              <a
                href={props.paperUrl}
                className={`btn btn-primary mx-1 ${
                  props.paperDisabled ? "disabled" : ""
                }`}
                target="_blank"
                rel="noreferrer"
                draggable="false"
              >
                Paper
              </a>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
