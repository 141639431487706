import React from "react";

interface ExamCardProps {
  title: string;
  subtitle: string;
  date: string;
  examUrl: string;
  buttonText: string;
  copyText: string;
}

const ExamCard = ({
  title,
  subtitle,
  date,
  examUrl,
  buttonText
}: ExamCardProps) => {
  return (
    <div className="col-lg-3 mb-4 highlight">
      <div className="card" style={{ textAlign: "center" }}>
        <div className="card-body">
          <h5 className="card-title">{title}</h5>
          <h6 className="card-title">{subtitle}</h6>
          <p className="card-text">{date}</p>
          <a
            href={examUrl}
            className="btn btn-primary mx-1"
            target="_blank"
            rel="noopener noreferrer"
            draggable="false"
          >
            {buttonText}
          </a>
        </div>
      </div>
    </div>
  );
};

export default ExamCard;
