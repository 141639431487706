import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

interface Metadata {
  title: string;
  description: string;
}

interface MetadataUpdaterProps {
  metadataMap: { [key: string]: Metadata };
}

const MetadataUpdater: React.FC<MetadataUpdaterProps> = ({ metadataMap }) => {
  const location = useLocation();

  useEffect(() => {
    const pageMetadata = metadataMap[location.pathname] || {
      title: "Error 404 (Not Found)!!1"
    };

    document.title = pageMetadata.title;

    const descriptionMeta = document.querySelector('meta[name="description"]');
    if (descriptionMeta) {
      descriptionMeta.setAttribute("content", pageMetadata.description);
    } else {
      const newMeta = document.createElement("meta");
      newMeta.name = "description";
      newMeta.content = pageMetadata.description;
      document.head.appendChild(newMeta);
    }

    // Open Graph & Twitter metadata
    const metaTags = [
      { name: "og:title", content: pageMetadata.title },
      { name: "og:description", content: pageMetadata.description },
      { name: "twitter:title", content: pageMetadata.title },
      { name: "twitter:description", content: pageMetadata.description },
    ];

    metaTags.forEach(({ name, content }) => {
      let tag = document.querySelector(`meta[property="${name}"], meta[name="${name}"]`);
      if (tag) {
        tag.setAttribute("content", content);
      } else {
        const newMeta = document.createElement("meta");
        newMeta.setAttribute(name.startsWith("og:") ? "property" : "name", name);
        newMeta.setAttribute("content", content);
        document.head.appendChild(newMeta);
      }
    });

  }, [location.pathname, metadataMap]);

  return null;
};

export default MetadataUpdater;