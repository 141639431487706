import React, {useEffect} from "react";
import Header from "../../components/shared/Header";
import Navigation from "../shared/Navigation";
import Footer from "../shared/Footer";
import ProjectCard from "./components/ProjectCard";
import PaperCard from "./components/PaperCard";

const ProjectsPage = () => {
  return (
    <div>
      <Header />
      <Navigation />
      <div className="container mt-4">
        <h3>Programming Projects</h3>
        <p className="lead">
          Explore some of the projects I've worked on during my undergraduate academic
          career.
        </p>
        <div className="row">
          <PaperCard
            title="Emergent Dialogue Systems Survey"
            description="A comprehensive review of emergent dialogue systems and their architectures"
            paperUrl="https://arxiv.org/abs/2305.16324"
            buttonText={"arXiv"}
            cardOverlay="Talking with Machines"
          />
          <ProjectCard
            title="pyclassify"
            description="Language model trained on Python snippets to predict code readability"
            githubUrl="https://github.com/willtholke/pyclassify"
            paperUrl="https://github.com/willtholke/pyclassify/blob/main/manual/manual.pdf"
          />
          <ProjectCard
            title="Chameleon Cloud Widgets"
            description="iPython widgets for interacting with the Chameleon Cloud testbed"
            githubUrl="https://github.com/ChameleonCloud/python-chi/pull/33"
          />
          <ProjectCard
            title="Neural Net"
            description="My first implementation of a neural network in Python from scratch"
            githubUrl="https://github.com/willtholke/Neural-Net"
            paperUrl="https://github.com/willtholke/Neural-Net/blob/main/summary.pdf"
          />
          <ProjectCard
            title="Gitlet"
            description="A simpler rendition of Linus Torvalds' Git in 1300-ish lines of Java"
            githubUrl="https://www.youtube.com/watch?v=dQw4w9WgXcQ&ab_channel=RickAstley"
          />
          <ProjectCard
            title="Scheme Interpreter"
            description="Remember Scheme? Interpret your code with lexical analysis in Python"
            githubDisabled={true}
          />
          <ProjectCard
            title="Enigma"
            description="Encrypt messages with the German Enigma machine used in WWII"
            githubDisabled={true}
          />
          <ProjectCard
            title="Flood"
            description="Test your decision-making skills with the GUI puzzle game 'Flood'"
            githubDisabled={true}
          />
          <ProjectCard
            title="k-NN Movie Classifier"
            description="Classify movies using k-NN to identify the k most similar movies"
            githubDisabled={true}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ProjectsPage;
