import React from "react";

const AboutMe = () => {
  return (
    <div className="container mt-4">
      <div className="container mt-4 p-5 bg-light rounded-3">
        <div className="container-fluid py-2">
          <h2 className="display-6 fw-bold">About me</h2>
          <h5 className="fw-bold">University Edition</h5>
          <p className="col-md-12 fs-5">
            During the fall 2021 semester, I served on course staff as an Academic Intern for CS 61A
            (The Structure & Interpretation of Computer Programs) in the EECS
            department and, continuing into the spring, completed research under PhD Paul Raftery at UC
            Berkeley's Center for the Built Environment. I also worked
            under Kate Keahy as a Research Intern for{" "}
            <a
              href="https://www.chameleoncloud.org/"
              target="_blank"
              rel="noreferrer"
              style={{ color: "light blue" }}
            >
              Chameleon Cloud
            </a>{" "}
            at the University of Chicago during the following fall semester. In addition to my focus on software engineering, I 
            take interest in LLMs and AI research as well as building large,  
            scalable distributed systems. Back when I was a student at UC Berkeley, you could catch me on the glade at
            lunchtime–playing spikeball, relaxing in my hammock, or listening to{" "}
            <a
              href="https://open.spotify.com/track/4mIBcj18bcjSFeQQfIKStp?si=578a7f47c22a466e"
              target="_blank"
              rel="noreferrer"
              style={{ color: "grey" }}
            >
              Over & Over
            </a>{" "}
            by{" "}
            <a
              href="https://open.spotify.com/artist/20yuGdfbRkW0HH3OfG1Nkg?si=A3Rmwcq4SvuFEHO21l0Gfg"
              target="_blank"
              rel="noreferrer"
              style={{ color: "grey" }}
            >
              No Mana
            </a>
            . Sometimes I play Skyrim or Subnautica.
          </p>
          {/* <h2 className="display-7 fw-bold">What is Will...</h2>
          <h4 className="display-10 fw-bold">studying?</h4>
          <ul style={{ fontSize: "115%" }}>
            <li>N/A :)</li>
          </ul>
          <h4 className="display-10 fw-bold">doing when he's not studying?</h4>
          <div>
            <ul style={{ fontSize: "115%", marginTop: "-5px" }}>
              <li>
                swimming at Spieker or sneaking onto Edwards for track practice
                with the triathlon team
              </li>
              <li>
                reminiscing on the days when I used to take naps at the library
                in Wurster Hall
              </li>
            </ul>
          </div>
          <h4 className="display-10 fw-bold">building?</h4>
          <ul style={{ fontSize: "115%", marginTop: "-5px" }}>
            <li>
              <a
                href="https://pcpartpicker.com/user/wtholke/saved/#view=qsB4pg"
                target="_blank"
                rel="noreferrer"
                style={{ color: "blue" }}
              >
                i7-12700K x GeForce RTX 3080 PC
              </a>
            </li>
          </ul> */}
        </div>
      </div>
    </div>
  );
};

export default AboutMe;
