import React from "react";
import SkillsCard from "../Cards/SkillsCard";

const SkillsSection: React.FC = () => {
  const programmingLanguages = [
    "Python",
    "Java",
    "Kotlin",
    "JavaScript & TypeScript",
    "HTML & CSS",
    "Ruby",
    "Go",
    "C",
    "R",
    "SQL",
    "Shell"
  ];

  const industryTechnologies = [
    {
      title: "Industry Technologies",
      subtitle: "Frontend Web Development",
      skills: [
        "React.js",
        "Next.js",
        "Remix.js",
        "Node.js",
        "Tailwind CSS",
        "Shadcn UI, Material UI, Bootstrap, Jupyter IPyWidgets",
      ]
    },
    {
      subtitle: "Backend Web Development",
      skills: [
        "Spring Boot",
        "Dropwizard",
        "PostgreSQL",
        "MongoDB",
        "Docker",
        "Vercel, Heroku, Google Cloud",
      ]
    },
    {
      subtitle: "Data Science & Machine Learning",
      skills: [
        "pandas, matplotlib, seaborn, plotly [Python]",
        "scikit-learn, NumPy, PyTorch, LangChain [Python]",
        "tidyverse [R]",
      ],
    },
    {
      subtitle: "Dev Tools",
      skills: [
        "Datadog, Sentry [Observability]",
        "Git, GitHub, Bitbucket [Version Control]",
        "Linear, Jira, Notion, Confluence, Trello",
      ],
    },
    {
      subtitle: "Academic & Other",
      skills: ["LaTeX", "Overleaf", "Adobe Photoshop & After Effects"],
    },
  ];

  return (
    <div>
      <h3 className="mb-3">Technical Skills</h3>
      <div className="card mb-3">
        <div className="card-body">
          <SkillsCard
            title="Programming Languages"
            skills={programmingLanguages}
          />
        </div>
      </div>

      <div className="card mb-3">
        <div className="card-body">
          {industryTechnologies.map((tech, index) => (
            <SkillsCard
              key={index}
              title={tech.title}
              subtitle={tech.subtitle}
              skills={tech.skills}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default SkillsSection;
