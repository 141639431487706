import React, {useEffect} from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  text-align: center;
  max-width: 1000px;
`;

const CloudSection = () => {
  return (
    <Container className="container mt-4">
      <h2 style={{ textAlign: "center" }}>It looks like you're trying to access my private-access cloud tools</h2>
      <p>These aren't publicly available, yet...</p>
      <Link to="/">
        <button className="btn btn-primary btn-md" type="button">
          Back to Home
        </button>
      </Link>
    </Container>
  );
};

export default CloudSection;
