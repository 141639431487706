import React, { useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";

const Navigation = () => {
  const location = useLocation();

  const navItems = useMemo(
    () => [
      { path: "/", label: "Home" },
      { path: "/notes", label: "Notes" },
      { path: "/projects", label: "Projects" },
      { path: "/resume", label: "Resume" },
    ],
    []
  );

  const getNavLinkClass = (path: string) => {
    return location.pathname === path ? "nav-link active" : "nav-link";
  };

  const renderNavItem = (item: { path: string; label: string }) => (
    <li className="nav-item" key={item.path}>
      <NavLink to={item.path} className={getNavLinkClass(item.path)}>
        <span className="unselectable">{item.label}</span>
      </NavLink>
    </li>
  );

  return (
    <div className="container">
      <nav>
        <ul className="nav nav-pills">{navItems.map(renderNavItem)}</ul>
      </nav>
    </div>
  );
};

export default Navigation;
