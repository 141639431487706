import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import ProjectsPage from "./components/ProjectsPage/ProjectsPage";
import NotesPage from "./components/NotesPage/NotesPage";
import ResumePage from "./components/ResumePage/ResumePage";
import { Analytics } from "@vercel/analytics/react";
import { SpeedInsights } from "@vercel/speed-insights/react"
import MetadataUpdater from "./components/utils/MetadataUpdater";
import ErrorPage from "./components/ErrorPage/ErrorPage";
import CloudPage from "./components/CloudPage/CloudPage";

const metadataMap = {
  "/": {
    title: "Will Tholke",
    description: "Innovative developer and UC Berkeley Computer Science alum. " +
      "Explore my open-source projects, detailed course notes, and private-access cloud tools.",
  },
  "/projects": {
    title: "Projects · Will Tholke",
    description: "A collection of some of my software projects.",
  },
  "/notes": {
    title: "Notes · Will Tholke",
    description: "Detailed UC Berkeley CS course notes and cheat sheets.",
  },
  "/resume": {
    title: "Resume · Will Tholke",
    description: "My experience, skills, and professional background.",
  },
  "/cloud": {
    title: "Cloud · Will Tholke",
    description: "Private cloud tools and utilities.",
  },
};

function App() {
  return (
    <Router>
      <Analytics />
      <SpeedInsights />
      <MetadataUpdater metadataMap={metadataMap} />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/projects" element={<ProjectsPage />} />
        <Route path="/notes" element={<NotesPage />} />
        <Route path="/resume" element={<ResumePage />} />
        <Route path="/cloud" element={<CloudPage />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
    </Router>
  );
}

export default App;